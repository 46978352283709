import React from 'react';
import classNames from 'classnames/bind';
import ContainerDimensions from 'react-container-dimensions';
import Swiper from 'react-id-swiper';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';

import styles from './Bakery.module.scss';
import MenuItemDesc from './common/MenuItemDesc';
import MenuSetLeft from './common/MenuSetLeft';
import MenuSetRight from './common/MenuSetRight';
import Divider from './common/Divider';
import menus from '../../datas/salemenu/bakery.js';
import { params5 } from './common/swiperParam';

const cx = classNames.bind(styles);
const { cakes, breads, castellas } = menus;

const Bakery = () => (
  <div>
    {/* 케이크 */}
    <MenuSetLeft
      engTitle="Cake"
      korTitle="케이크"
      image={require('../../assets/images/menu/bakery/cake/big.png')}
      content={<span>엄선된 재료만을 사용하는 아티제 케이크</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip1', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {cakes.map((cake, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={cake} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 브레드 */}
    <MenuSetRight
      engTitle="Bread"
      korTitle="브레드"
      image={require('../../assets/images/menu/bakery/bread/big.png')}
      content={<span>좋은 재료로, 가장 좋은 시간에 맛 볼 수 있도록, 오늘 구운 브레드</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip2', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetRight>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
         <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {breads.map((bread, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={bread} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 카스텔라 */}
    <MenuSetRight
      engTitle="Castella"
      korTitle="카스텔라"
      image={require('../../assets/images/menu/bakery/castella/big.png')}
      content={
        <span>
          입 안에 맴도는 부드럽고 섬세한 맛<br />
          선물용 카스텔라
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip4', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetRight>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {castellas.map((castella, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={castella} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Bakery;
